.header-main {
  margin: auto;
  text-align: center;
}

.header-text {
  font-size: 1.3rem;
}

@media only screen and (min-width: 750px) {
  .header-text {
    font-size: 1.8rem;
  }
}
