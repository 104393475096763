.menu-main > ul {
  list-style: none;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding-left: 0;
  margin-bottom: 3%;
}

.menu-title {
  font-size: 1rem;
  margin-bottom: 0;
}

button {
  background-color: lightgray;
}

.menu-nav-div {
  display: flex;
  flex-direction: row;
  margin-bottom: 3%;
}

.menu-nav-button {
  width: 50%;
  font-size: 1.2rem;
  font-weight: bolder;
}

.menu-selection {
  margin-top: 0;
  font-size: 1.2rem;
  border: 2px solid black;
  width: 50px;
  height: 50px;
  display: inline-block;
  align-items: center;
  padding: 0;
  text-align: center;
}

.active {
  background-color: lightyellow;
}

.menu-save {
  border: 2px solid black;
  font-size: 1.5rem;
  width: 100%;
}

.menu-random {
  margin-top: 3%;
}
